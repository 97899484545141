
































































import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import DashboardRenderer from '@/components/dashboards/DashboardRenderer.vue';
import cloneDeep from 'lodash/cloneDeep';
import { apiGet } from '../store';

@Component({
  components: {
    DashboardRenderer,
  },
})
export default class DashboardBuilding extends Vue {
  @Prop() public id!: number;
  public $store: any;
  public loading: boolean = false;
  public building: any = null;
  public dashboardData: any = null;
  public selectDashboardPopup: boolean = false;
  public dashboards: any = null;
  public dashboardId: any = null;
  public dashboardName: any = null;
  public dashboardType: any = null;

  public created() {
    this.loading = true;
    const dashboardId = localStorage.getItem('dbBuildingId');
    if (dashboardId !== null) {
      this.dashboardId = parseInt(dashboardId, 10);
    }
    this.refresh();
  }

  public refresh() {
    this.building = this.$store.getters.getBuildingFromId(this.id);
    this.loadBuilding();
    if (this.dashboardId !== null) {
      this.refreshDashboard();
    } else {
      this.loadFirstDashboard();
    }
  }

  public loadFirstDashboard() {
    this.loadDashboardsList().then(() => {
      this.dashboardId = this.dashboards[0].id;
      this.refreshDashboard();
    });
  }

  private openDashboardPopup() {
    this.loadDashboardsList();
    this.selectDashboardPopup = true;
  }

  private loadDashboardsList() {
    if (this.dashboards !== null) {
      return new Promise((resolve, reject) => {
        resolve(this.dashboards);
      });
    }
    return apiGet(`/dashboards`).then((response: any) => {
      this.dashboards = response.filter(
        (entry: any) => entry.dashboard_type === 'building');
    }).catch((error: any) => {
      this.globalError(error);
    });
  }

  private loadBuilding() {
    this.$store.dispatch('ensureBuilding', {
      buildingId: this.id,
      params: { recursive: true },
    })
    .then((response: any) => {
      this.building = response;
      this.loading = false;
    })
    .catch((error: any) => {
      this.globalError(error);
    });
  }

  private refreshDashboard() {
    apiGet(`/dashboard/${this.dashboardId}`).then((response: any) => {
      this.dashboardData = response.content;
      this.dashboardName = response.name;
      this.dashboardType = response.dashboard_type;
    }).catch((error: any) => {
      if (error.status === 404) {
        if (this.dashboardId !== this.dashboards[0].id) {
          this.dashboardId = this.dashboards[0].id;
          this.refreshDashboard();
        }
      }
      return;
    });
  }

  private selectDashboard(dashboardId: number) {
    this.dashboardId = dashboardId;
    localStorage.setItem('dbBuildingId', this.dashboardId);
    this.refreshDashboard();
    this.selectDashboardPopup = false;
  }

  get content_with_variables() {
    if (!this.dashboardData) {
      return;
    }
    const output = cloneDeep(this.dashboardData);
    output['variables'] = {
        building_id: this.id,
        dashboard_type: this.dashboardType,
    };
    return output;
  }

  private onBuildingChanged(value: number) {
    if (this.id === value) {
      return;
    }
    this.$router.push({
      name: 'dashboard-building',
      params: { id: '' + value },
    });
  }
}
